<template>

<div class="cta">

	<p class="cta-text">{{ block.text }}</p>

	<div class="cta-links">

		<router-link v-if="$_.contains(block.contexts, $constants.context.aa)" :to="$link('contact', 'aa')" class="cta-links-item is-aa"><span>AA</span></router-link>
		<router-link v-if="$_.contains(block.contexts, $constants.context.afa)" :to="$link('contact', 'afa')" class="cta-links-item is-afa"><span>AFA</span></router-link>
		<router-link v-if="$_.contains(block.contexts, $constants.context.stars)" :to="$link('contact', 'stars')" class="cta-links-item is-stars"><span>STARS</span></router-link>

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.cta {
	border-radius: 42px;
	background-color: #f5f5f5;
	justify-content: center;
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.cta-text {
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: #1a234c;
	margin-bottom: 20px;
}

.cta-links {
	display: flex;
	justify-content: center;
	width: 100%;
}

.cta-links-item {
	width: 170px;
	height: 105px;
	display: block;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin: 0px 10px;
}

.cta-links-item span {
	visibility: hidden;
}

.cta-links-item.is-aa {
	background-image: url(~@/assets/logo.aa.png);
}

.cta-links-item.is-afa {
	background-image: url(~@/assets/logo.afa.png);
}

.cta-links-item.is-stars {
	background-image: url(~@/assets/logo.stars.png);
}

</style>
